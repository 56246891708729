import React from "react";
import {Navigate, Route} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

const ProtectedRoute = ({children}) => {
	const {authed} = useAuth();

	if (authed) {
		return children;
	}

	return <Navigate to="/login"/>;
};

export default ProtectedRoute;