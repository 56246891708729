import React, {useState} from "react";

const ImportHistoryForm = () => {
	const [historyFile, setHistoryFile] = useState();

	const handleFileChange = (e) => {
		if (e.target.files) {
			setHistoryFile(e.target.files[0]);
		}
	}

	const submit = (event) => {
		event.preventDefault();

		if (!historyFile) {
			alert("Please select a file before clicking import."); // TODO: Make modal
			return;
		}

		let formData = new FormData();
		formData.append("file", historyFile);

		fetch("/api/import-game-history", {
			method: "POST",
			body: formData

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully imported games!"); // TODO: Replace with modal
				window.location.reload();
			} else {
				response.json().then(json => {
					console.log(json);
				});
			}
		});
	};

	return (
		<form className="importHistoryForm">
			<div>
				<label htmlFor="historyFile">History File (.csv):</label>
				<input name="historyFile"
					   className="dateField"
					   type="file"
					   accept="text/csv"
					   onChange={handleFileChange}/>
			</div>
			<button className="submitButton" onClick={submit}>Import</button>
		</form>
	);
};

export default ImportHistoryForm;