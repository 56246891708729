import React from "react";
import {Link, useNavigate} from "react-router-dom";

import "./notFound.css";
import Logo from "../logo/logo";

const NotFound = () => {
	const navigate = useNavigate();

	return (
		<div className="notFoundContainer">
			<Logo/>
			<div className="notFoundContents">
				<p className="notFoundText">You seem a little lost.</p>
				<button className="notFoundButton" onClick={() => navigate("/")}>Home</button>
			</div>
		</div>
	);
};

export default NotFound;