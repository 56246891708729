import "./about.css";
import Navbar from "../navbar/navbar";

const Patch = ({version, date, fixes}) => {
	return (
		<div className="releaseContainer">
			<p className="patchVersionNumber">v{version}<span className="versionDate"> - {date}</span></p>
			<ul>
				{
					fixes && fixes.length > 0 ?
						fixes.map((fix, index) => (
							<li>
								<p className="featureTitle">{fix.title}</p>
								<ul>
									{
										fix.lines.map((line, index) => (
											<li className="featureLine">{line}</li>
										))
									}
								</ul>
							</li>
						))
						: "None"
				}
			</ul>
		</div>
	);
};

const Release = ({version, date, features, children}) => {
	return (
		<div className="releaseContainer">
			<p className="versionNumber">v{version}<span className="versionDate"> - {date}</span></p>
			<div className="changesDescription">
				<p className="newFeaturesTitle">New Features:</p>
				<ul>
					{
						features && features.length > 0 ?
							features.map((feature, index) => (
								<li>
									<p className="featureTitle">{feature.title}</p>
									<ul>
										{
											feature.lines.map((line, index) => (
												<li className="featureLine">{line}</li>
											))
										}
									</ul>
								</li>
							))
							:
							"None"
					}
				</ul>

				<p className="newFeaturesTitle">Fixes:</p>
				<ul>
					{children}
				</ul>
			</div>
		</div>
	);
};

const About = () => {
	return (
		<div className="aboutContainer">
			<div className="navbar">
				<Navbar hideExtras={false}/>
			</div>

			<div className="whatsNewContainer">
				<p className="whatsNewTitle">What's New</p>
				<p className="currentVersionText">Currently running Suited v0.5.1</p>

				<div className="releases">
					<Release version="0.5.0"
							 date="2024-09-13"
							 features={[
								 {
									 title: "Tools",
									 lines: [
										 "Reworked the tools page, allowing for infinite users in the seat shuffler and improving visuals."
									 ]
								 }
							 ]}>

						<Patch version="0.5.1"
							   date="2024-09-13"
							   fixes={[
								   {
									   title: "Leagues",
									   lines: [
										   "Fixed sort order of \"Biggest Loss\" category."
									   ]
								   }
							   ]}/>
					</Release>

					<Release version="0.4.0"
							 date="2024-09-12"
							 features={[
								 {
									 title: "Leagues",
									 lines: [
										 "Reworked the leagues page, and added moderation abilities to league owners."
									 ]
								 }
							 ]}>
					</Release>

					<Release version="0.3.0"
							 date="2024-09-07"
							 features={[
								 {
									 title: "Analytics",
									 lines: [
										 "Reworked the analytics page, improving visuals and displaying more relevant statistics."
									 ]
								 },
								 {
									 title: "Login",
									 lines: [
										 "Remember me functionality is now working."
									 ]
								 }
							 ]}>

						<Patch version="0.3.1"
							   date="2024-09-08"
							   fixes={[
								   {
									   title: "Analytics",
									   lines: [
										   "Fixed profit/loss percentage statistic for time range stats."
									   ]
								   },
								   {
									   title: "Dashboard",
									   lines: [
										   "Added padding to game history table."
									   ]
								   }
							   ]}/>
					</Release>

					<Release version="0.2.0"
							 date="2024-08-18"
							 features={[
								 {
									 title: "Notes",
									 lines: [
										 "Record important information and highlights for your sessions with our notes feature."
									 ]
								 }
							 ]}>
						<Patch version="0.2.1"
							   date="2024-08-18"
							   fixes={[
								   {
									   title: "About",
									   lines: [
										   "Fixed text alignment."
									   ]
								   }
							   ]}/>

						<Patch version="0.2.2"
							   date="2024-08-19"
							   fixes={[
								   {
									   title: "Modals",
									   lines: [
										   "Reworked modal visuals and internal functionality."
									   ]
								   }
							   ]}/>

						<Patch version="0.2.3"
							   date="2024-08-19"
							   fixes={[
								   {
									   title: "Dashboard",
									   lines: [
										   "Added notes to spreadsheet import."
									   ]
								   }
							   ]}/>

						<Patch version="0.2.4"
							   date="2024-08-20"
							   fixes={[
								   {
									   title: "Misc",
									   lines: [
										   "Fixed \"missing date\" error when creating a game.",
										   "Increased ratelimits."
									   ]
								   }
							   ]}/>

						<Patch version="0.2.5"
							   date="2024-08-24"
							   fixes={[
								   {
									   title: "Misc",
									   lines: [
										   "Fixed notes not being saved."
									   ]
								   }
							   ]}/>

						<Patch version="0.2.6"
							   date="2024-08-24"
							   fixes={[
								   {
									   title: "Misc",
									   lines: [
										   "Added restrictions for usernames and passwords."
									   ]
								   }
							   ]}/>


						<Patch version="0.2.7"
							   date="2024-08-31"
							   fixes={[
								   {
									   title: "Misc",
									   lines: [
										   "Redirect to login page when not logged in instead of a white screen."
									   ]
								   }
							   ]}/>

						<Patch version="0.2.8"
							   date="2024-09-01"
							   fixes={[
								   {
									   title: "Misc",
									   lines: [
										   "Changed consistency formula + made it a percentage."
									   ]
								   }
							   ]}/>


						<Patch version="0.2.9"
							   date="2024-09-01"
							   fixes={[
								   {
									   title: "Profile",
									   lines: [
										   "Fixed consistency breaking profile page."
									   ]
								   },
								   {
									   title: "Dashboard",
									   lines: [
										   "Adjusted button sizing."
									   ]
								   }
							   ]}/>
					</Release>

					<Release version="0.1.0"
							 date="2024-08-17"
							 features={[
								 {
									 title: "Homepage",
									 lines: [
										 "An informational page where users can easily view all the features Suited has to offer.",
										 "Easy access to the entire app."
									 ]
								 },
								 {
									 title: "Dashboard",
									 lines: [
										 "Centralized hub to track all of your most important poker stats and sessions.",
										 "Customizable filters to narrow down your search and inspect specific game types.",
										 "Overview of performance, bankroll, etc."
									 ]
								 },
								 {
									 title: "Analysis",
									 lines: [
										 "Detailed statistics showing your win rates, performance trends, and other key information."
									 ]
								 },
								 {
									 title: "Leagues",
									 lines: [
										 "Join, manage, and compete in poker leagues against your friends or strangers.",
										 "Track your current standing in multiple league categories.",
										 "Compare your statistics to other players in your league."
									 ]
								 },
								 {
									 title: "Tools",
									 lines: [
										 "A suite of poker tools, including a seat shuffler and a chip counter, making organizing home games easy."
									 ]
								 },
								 {
									 title: "About",
									 lines: [
										 "View the changelog and other important information about the platform."
									 ]
								 }
							 ]}>

						<Patch
							version="0.1.1"
							date="2024-08-17"
							fixes={[
								{
									title: "Signup",
									lines: [
										"Confirm password field now properly confirms that passwords are the same."
									]
								}
							]}/>
						<Patch
							version="0.1.2"
							date="2024-08-17"
							fixes={[
								{
									title: "About",
									lines: [
										"Changed changelog layout."
									]
								}
							]}/>

						<Patch
							version="0.1.3"
							date="2024-08-17"
							fixes={[
								{
									title: "About",
									lines: [
										"Version bump."
									]
								}
							]}/>

						<Patch
							version="0.1.4"
							date="2024-08-17"
							fixes={[
								{
									title: "Dashboard",
									lines: [
										"Fixed mobile add game button."
									]
								}
							]}/>

						<Patch
							version="0.1.5"
							date="2024-08-18"
							fixes={[
								{
									title: "Dashboard",
									lines: [
										"Replaced game edit text with an icon.",
										"Profit text now wraps on game entries."
									]
								}
							]}/>

						<Patch
							version="0.1.6"
							date="2024-08-18"
							fixes={[
								{
									title: "About",
									lines: [
										"Fixed scrollbar appearing in the middle of the page."
									]
								},
								{
									title: "Home",
									lines: [
										"Fixed text clipping on mobile."
									]
								},
								{
									title: "Profile",
									lines: [
										"Fixed remove friend button appearing on other user's friend lists."
									]
								},
								{
									title: "Signup",
									lines: [
										"Fixed an bug allowing multiple accounts to be created with the same username."
									]
								},
								{
									title: "Other",
									lines: [
										"Removed a hidden field from the navbar account dropdown."
									]
								}
							]}/>

						<Patch
							version="0.1.7"
							date="2024-08-18"
							fixes={[
								{
									title: "Dashboard",
									lines: [
										"Fixed toggleable info on game entries always showing."
									]
								}
							]}/>

						<Patch
							version="0.1.8"
							date="2024-08-18"
							fixes={[
								{
									title: "Navbar",
									lines: [
										"Fixed navbar spacing on mobile."
									]
								}
							]}/>

						<Patch
							version="0.1.9"
							date="2024-08-18"
							fixes={[
								{
									title: "Navbar",
									lines: [
										"Fixed navbar spacing on desktop."
									]
								}
							]}/>

						<Patch
							version="0.1.10"
							date="2024-08-18"
							fixes={[
								{
									title: "Profile",
									lines: [
										"Hiding add friend button after request is sent."
									]
								}
							]}/>

						<Patch
							version="0.1.11"
							date="2024-08-18"
							fixes={[
								{
									title: "Profile",
									lines: [
										"Hiding add friend button if the user you're viewing has sent you a request already."
									]
								}
							]}/>

						<Patch
							version="0.1.12"
							date="2024-08-18"
							fixes={[
								{
									title: "Profile",
									lines: [
										"Show different text depending on whether a friend request was sent/received."
									]
								},
								{
									title: "Other",
									lines: [
										"Made the entire account dropdown clickable."
									]
								}
							]}/>

						<Patch
							version="0.1.13"
							date="2024-08-18"
							fixes={[
								{
									title: "Signup and Login",
									lines: [
										"Added buttons to go to login, home, or signup."
									]
								}

							]}/>

						<Patch
							version="0.1.14"
							date="2024-08-18"
							fixes={[
								{
									title: "Signup and Login",
									lines: [
										"Changed form button sizing."
									]
								},
								{
									title: "Dashboard and Analytics",
									lines: [
										"Custom tooltip component for all charts.",
										"Added custom tooltip on profit graph."
									]
								},
								{
									title: "Other",
									lines: [
										"Added logo to all pages."
									]
								}
							]}/>
					</Release>
				</div>
			</div>
		</div>
	);
};

export default About;