import "./tools.css";
import React from "react";
import SeatShuffler from "../seat-shuffler/seatShuffler";
import ChipCalculator from "../chip-calculator/chipCalculator";
import Page from "../page/page";

const Tools = () => {
	return (
		<Page>
			<div className="toolsContainer">
				<div className="sectionContainer seatShuffler">
					<p className="sectionHeader">Seat Shuffler</p>
					<SeatShuffler/>
				</div>

				<div className="sectionContainer chipCalculator">
					<p className="sectionHeader">Chip Calculator</p>
					<ChipCalculator/>
				</div>
			</div>
		</Page>
	);
}

export default Tools;