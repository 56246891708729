import React, {useState} from "react";
import ColoredStatistic from "../colored-statistic/coloredStatistic";
import {formatCurrency} from "../utils";
import Modal from "../modal/modal";

import "./gameEntry.css";
import GameForm from "../forms/game-form/gameForm";

const GameEntry = ({gameId, allTags, tags, date, type, sb, bb, chipsBought, leftWith, pL, pLPercent, notes}) => {
	const getDateObj = () => {
		let d = new Date(0);
		d.setUTCMilliseconds(date);
		return d;
	}

	const [showEditModal, setShowEditModal] = useState(false);
	const [showTogglables, setShowTogglables] = useState(false);

	return (
		<div className={"gameEntry" + (showTogglables ? " toggled" : "")}
			 onClick={() => setShowTogglables(!showTogglables)}>
			<div className={"pLImageContainer" + (pL >= 0 ? " greenImg" : " redImg")}>
				<img className="pLImage" src={pL >= 0 ? "/up-arrow.png" : "/down-arrow.png"} alt="Profit indicator"/>
			</div>

			<div className="entryContent">
				<div className="mainInfo">
					<div className="basicInfo">
						<p>{(getDateObj().getUTCMonth() + 1) + "/" + getDateObj().getUTCDate() + "/" + getDateObj().getUTCFullYear()}</p>
						<p className="entryInfoText">{type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()} • {`$${sb}/$${bb}`}</p>
					</div>

					<div className="entryProfit">
						<p><ColoredStatistic value={formatCurrency(pL)} isGreen={pL >= 0}/></p>
						<p>(<ColoredStatistic value={`${(pLPercent * 100).toFixed(2)}%`} isGreen={pLPercent >= 0}/>)</p>
					</div>

					<button className="editGameButton" onClick={() => setShowEditModal(true)}>
						<img className="editedIcon editGameIcon" src="/icons/edit.png" alt="Edit icon"/>
					</button>

					<Modal show={showEditModal} handleClose={() => setShowEditModal(false)}>
						<div>
							<p className="sectionHeader">Edit Game</p>
							<div className="editGameContainer">
								<GameForm
									type="edit"
									gameId={gameId}
									allTags={allTags}
									prevTags={tags}
									prevDate={date}
									prevType={type}
									prevSb={sb}
									prevBb={bb}
									prevBought={chipsBought}
									prevCashed={leftWith}
									prevNotes={notes}
								/>
							</div>
						</div>
					</Modal>
				</div>

				<div className={"togglableInfo" + (showTogglables ? "" : "hide")}>
					<p className={showTogglables ? "" : "hide"}>Bought: {formatCurrency(chipsBought)}</p>
					<p className={showTogglables ? "" : "hide"}>Cashed: {formatCurrency(leftWith)}</p>
					<p className={showTogglables ? "" : "hide"}>Tags: {tags.length === 0 ? "None" : tags.join(", ")}</p>
					<p className={showTogglables ? "" : "hide"}>Notes: {notes}</p>
				</div>
			</div>
		</div>
	)
		;
	// TODO: Make a formatPercent function
}

export default GameEntry;