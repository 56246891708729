import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import './App.css';
import Layout from "./components/layout/layout";
import Dashboard from "./components/dashboard/dashboard";
import NotFound from "./components/not-found/notFound";
import Signup from "./components/signup/signup";
import Login from "./components/login/login";
import Tools from "./components/tools/tools";
import Leagues from "./components/leagues/leagues";
import Home from "./components/home/home";
import Analytics from "./components/analytics/analytics";
import Profile from "./components/profile/profile";
import About from "./components/about/about";
import ProtectedRoute from "./components/protected-route/protectedRoute";
import {useAuth} from "./hooks/useAuth";

function App() {
	const {authed, loading} = useAuth();

	return (
		<div className="App">
			{loading ?
				// TODO: Loading page
				<div>Loading...</div>
				: (
					<BrowserRouter>
						<Routes>
							<Route path="/index.html" element={<Navigate to="/"/>}/>
							<Route path="/" element={<Layout/>}>
								<Route index element={<Home/>}/>
								<Route path="/dashboard" element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
								<Route path="/profile/*" element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
								<Route path="/signup" element={<Signup/>}/>
								<Route path="/login" element={<Login/>}/>
								<Route path="/tools" element={<ProtectedRoute><Tools/></ProtectedRoute>}/>
								<Route path="/leagues" element={<ProtectedRoute><Leagues/></ProtectedRoute>}/>
								<Route path="/analytics" element={<ProtectedRoute><Analytics/></ProtectedRoute>}/>
								<Route path="/about" element={<About/>}/>
								<Route path="*" element={<NotFound/>}/>
							</Route>
						</Routes>
					</BrowserRouter>
				)}
		</div>
	);
}

export default App;
