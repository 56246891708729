import GameEntry from "../game-entry/gameEntry";
import React from "react";

import "./gameEntryTable.css";


const GameEntryTable = ({gameHistory, allTags}) => {
	return (
		<div className="entryTable">
			{
				gameHistory.sort((a, b) => (b.date - a.date)).map((row) => (
					<GameEntry
						gameId={row.game_id}
						allTags={allTags}
						tags={row.tags}
						date={row.date}
						type={row.type}
						sb={row.sb}
						bb={row.bb}
						chipsBought={row.bought}
						leftWith={row.cashed}
						pL={row.cashed - row.bought}
						pLPercent={(row.cashed - row.bought) / row.bought}
						notes={row.notes}
					/>
				))
			}
		</div>
	);
}

export default GameEntryTable;