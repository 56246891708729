import "./analytics.css";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ColoredStatistic from "../colored-statistic/coloredStatistic";
import {calculateHistoryStats, formatCurrency} from "../utils";
import {
	Area,
	AreaChart, Bar,
	BarChart, Cell, Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from "recharts";
import Page from "../page/page";
import CustomTooltip from "../tooltip/customTooltip";
import {getLoggedInUsername, getMyGameHistory} from "../api";
import CircleBar from "../circle-bar/circleBar";

const Analytics = () => {
	const [gameHistory, setGameHistory] = useState([]);
	const [filteredGameHistory, setFilteredGameHistory] = useState([]);

	const [loggedInUsername, setLoggedInUsername] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		getLoggedInUsername().then((username) => setLoggedInUsername(username));
		getMyGameHistory().then((history) => {
			setGameHistory(history);
			setFilteredGameHistory(history);
		});
	}, []);

	let stats = calculateHistoryStats(filteredGameHistory);

// Adding entries to profit graph
	let graphData = [];
	let profit = 0;
	let moneyInTotal = 0;
	let moneyOutTotal = 0;

	filteredGameHistory.slice().sort((a, b) => (a.date - b.date)).forEach((row) => {
		profit += row.cashed - row.bought;
		moneyInTotal += row.bought;
		moneyOutTotal += row.cashed;

		let date = new Date(0);
		date.setUTCMilliseconds(row.date);

		graphData.push({
			date: `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`,
			profit: Math.round(profit * 100) / 100,
			change: Math.round((row.cashed - row.bought) * 100) / 100,
			in: Math.round(moneyInTotal * 100) / 100,
			out: Math.round(moneyOutTotal * 100) / 100
		});
	});

// Collecting tags
	let tags = [];
	gameHistory.forEach((row) => {
		if (row.hasOwnProperty('tags')) {
			row.tags.forEach((tag) => {
				let tagObj = {
					label: tag,
					value: tag
				};

				if (!tags.some(e => e.label === tag)) {
					tags.push(tagObj);
				}
			});
		}
	});

	const currentDate = new Date();
	const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
	const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
	const yearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
	let lastSevenDaysHistory = gameHistory.filter((entry) => currentDate - entry.date <= sevenDaysInMilliseconds);
	let lastThirtyDaysHistory = gameHistory.filter((entry) => currentDate - entry.date <= thirtyDaysInMilliseconds);
	let lastYearHistory = gameHistory.filter((entry) => currentDate - entry.date <= yearInMilliseconds);

	let sevenDaysStats = calculateHistoryStats(lastSevenDaysHistory);
	let thirtyDaysStats = calculateHistoryStats(lastThirtyDaysHistory);
	let yearStats = calculateHistoryStats(lastYearHistory);

	const InOutTooltip = ({active, payload, label}) => {
		if (active && payload && payload.length) {
			return (
				<CustomTooltip>
					<p className="tooltipDate">{label}</p>
					<p>Money In: <span className="green">{formatCurrency(payload[0].value)}</span></p>
					<p>Money Out: <span className="red">{formatCurrency(payload[1].value)}</span></p>
					<p>Profit: <span className="green">{formatCurrency(payload[2].value)}</span></p>
				</CustomTooltip>
			);
		}

		return null;
	};

	const ChangeTooltip = ({active, payload, label}) => {
		if (active && payload && payload.length) {
			return (
				<CustomTooltip>
					<p className="tooltipDate">{label}</p>
					<p>P/L: <span
						className={payload[0].value >= 0 ? "green" : "red"}>{formatCurrency(payload[0].value)}</span>
					</p>
				</CustomTooltip>
			);
		}

		return null;
	};

	const StakesProfitTooltip = ({active, payload, label}) => {
		if (active && payload && payload.length) {
			return (
				<CustomTooltip>
					<p className="tooltipDate">{label}</p>
					<p>Profit: <span
						className={payload[0].value >= 0 ? "green" : "red"}>{Math.round((payload[0].value) * 100)}%</span>
					</p>
				</CustomTooltip>
			);
		}

		return null;
	};

	let moneyTravelData = [
		{
			name: "Money In",
			value: moneyInTotal,
			fillColor: "var(--red)"
		},
		{
			name: "Money Out",
			value: moneyOutTotal,
			fillColor: "var(--green)"
		}
	];

	let winRateData = [
		{
			name: "Win Rate",
			value: stats.winRate,
			fillColor: "var(--purple)"
		},
		{
			name: "Blank",
			value: 1 - stats.winRate,
			fillColor: "var(--secondary-dark)"
		}
	];

	let accuracyData = [
		{
			name: "Accuracy",
			value: stats.accuracy,
			fillColor: "var(--pink)"
		},
		{
			name: "Blank",
			value: 1 - stats.accuracy,
			fillColor: "var(--secondary-dark)"
		}
	];

	let consistencyData = [
		{
			name: "Consistency",
			value: stats.variancePercentage,
			fillColor: "var(--blue)"
		},
		{
			name: "Blank",
			value: 100 - stats.variancePercentage,
			fillColor: "var(--secondary-dark)"
		}
	];

	let stakesMap = {};
	filteredGameHistory.forEach((session) => {
		let stakesKey = `$${session.sb}/$${session.bb}`;

		if (!stakesMap[stakesKey]) {
			stakesMap[stakesKey] = {stakes: stakesKey, profit: 0, bought: 0, cashed: 0};
		}

		stakesMap[stakesKey].profit += session.cashed - session.bought;
		stakesMap[stakesKey].bought += session.bought;
		stakesMap[stakesKey].cashed += session.cashed;
	});

	for (let stakesMapKey in stakesMap) {
		stakesMap[stakesMapKey].percentProfit = (stakesMap[stakesMapKey].cashed - stakesMap[stakesMapKey].bought) / stakesMap[stakesMapKey].bought;
	}

	let stakesData = Object.values(stakesMap);
	stakesData.sort((a, b) => {
		const [aSb, aBb] = a.stakes.replace("$", "").split("/").map(Number);
		const [bSb, bBb] = b.stakes.replace("$", "").split("/").map(Number);

		if (aSb === bSb) {
			return aBb - bBb;
		}

		return aSb - bSb;
	});

	return (
		<Page>
			<div className="analyticsContainer">
				<div className="sectionContainer rangedStatsContainer">
					<table>
						<thead>
						<tr>
							<th></th>
							<th>Entries</th>
							<th>P/L $</th>
							<th>P/L %</th>
							<th>Total In</th>
							<th>Total Out</th>
						</tr>
						</thead>

						<tbody>
						<tr>
							<td>Last 7 Days</td>
							<td>{sevenDaysStats.numGames}</td>
							<td>{<ColoredStatistic
								value={formatCurrency(sevenDaysStats.cumulativeTotal)}
								isGreen={sevenDaysStats.cumulativeTotal >= 0}
							/>}</td>
							<td>{<ColoredStatistic
								value={`${(((sevenDaysStats.totalOut - sevenDaysStats.totalIn) / sevenDaysStats.totalIn) * 100).toFixed(2)}%`}
								isGreen={(sevenDaysStats.totalOut - sevenDaysStats.totalIn) / sevenDaysStats.totalIn > 0}
							/>}</td>
							<td>{formatCurrency(sevenDaysStats.totalIn)}</td>
							<td>{formatCurrency(sevenDaysStats.totalOut)}</td>
						</tr>

						<tr>
							<td>Last 30 Days</td>
							<td>{thirtyDaysStats.numGames}</td>
							<td>{<ColoredStatistic
								value={formatCurrency(thirtyDaysStats.cumulativeTotal)}
								isGreen={thirtyDaysStats.cumulativeTotal >= 0}
							/>}</td>
							<td>{<ColoredStatistic
								value={`${(((thirtyDaysStats.totalOut - thirtyDaysStats.totalIn) / thirtyDaysStats.totalIn) * 100).toFixed(2)}%`}
								isGreen={(thirtyDaysStats.totalOut - thirtyDaysStats.totalIn) / thirtyDaysStats.totalIn > 0}
							/>}</td>
							<td>{formatCurrency(thirtyDaysStats.totalIn)}</td>
							<td>{formatCurrency(thirtyDaysStats.totalOut)}</td>
						</tr>

						<tr>
							<td>Last Year</td>
							<td>{yearStats.numGames}</td>
							<td>{<ColoredStatistic
								value={formatCurrency(yearStats.cumulativeTotal)}
								isGreen={yearStats.cumulativeTotal >= 0}
							/>}</td>
							<td>{<ColoredStatistic
								value={`${(((yearStats.totalOut - yearStats.totalIn) / yearStats.totalIn) * 100).toFixed(2)}%`}
								isGreen={(yearStats.totalOut - yearStats.totalIn) / yearStats.totalIn > 0}
							/>}</td>
							<td>{formatCurrency(yearStats.totalIn)}</td>
							<td>{formatCurrency(yearStats.totalOut)}</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div className="sectionContainer moneyGraphContainer">
					<p className="sectionHeader">Money In vs Out vs Profit</p>
					<div className="graphContainer">
						<ResponsiveContainer>
							<AreaChart data={graphData}>
								<Tooltip content={<InOutTooltip/>}/>
								<XAxis dataKey="date" hide={true}/>
								<YAxis hide={true}/>
								<Area className="graphArea"
									  type="monotone"
									  dataKey="in"
									  stroke="var(--green)"
									  strokeWidth={2}
									  fill="var(--green)"
									  fillOpacity={0.1}
								/>
								<Area className="graphArea"
									  type="monotone"
									  dataKey="out"
									  stroke="var(--red)"
									  strokeWidth={2}
									  fill="var(--red)"
									  fillOpacity={0.1}
								/>
								<Area className="graphArea"
									  type="monotone"
									  dataKey="profit"
									  stroke="var(--purple)"
									  strokeWidth={2}
									  fill="var(--purple)"
									  fillOpacity={0.1}
								/>

								<Legend/>
							</AreaChart>
						</ResponsiveContainer>
					</div>
				</div>

				<div className="sectionContainer stakesGraphContainer">
					<p className="sectionHeader">% Profit By Stake</p>
					<div className="graphContainer">
						<ResponsiveContainer>
							<BarChart data={stakesData}>
								<XAxis dataKey="stakes"/>
								<YAxis hide={true}/>
								<Tooltip content={<StakesProfitTooltip/>}/>
								<Bar dataKey="percentProfit" fill="var(--purple)"/>
							</BarChart>
						</ResponsiveContainer>
					</div>
				</div>

				<div className="sectionContainer pLGraphContainer">
					<p className="sectionHeader">Profit/Loss</p>
					<div className="graphContainer">
						<ResponsiveContainer>
							<BarChart data={graphData}>
								<XAxis dataKey="date" hide={true}/>
								<YAxis hide={true}/>
								<Tooltip content={<ChangeTooltip/>}/>
								<ReferenceLine y={0} stroke="var(--light)"/>
								<Bar dataKey="change">
									{
										graphData.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={entry.change >= 0 ? "var(--green)" : "var(--red)"}
											/>
										))
									}
								</Bar>
							</BarChart>
						</ResponsiveContainer>
					</div>
				</div>

				<div className="circleBarContainer moneyTravel">
					<CircleBar data={moneyTravelData}
							   dataName="ROI"
							   value={Math.round((stats.cumulativeTotal / stats.totalIn) * 100)}
					/>
				</div>

				<div className="circleBarContainer winRate">
					<CircleBar data={winRateData}
							   dataName="Win Rate"
							   value={Math.round((stats.winRate) * 100)}
					/>
				</div>

				<div className="circleBarContainer accuracy">
					<CircleBar data={accuracyData}
							   dataName="Accuracy"
							   value={Math.round((stats.accuracy) * 100)}
					/>
				</div>

				<div className="circleBarContainer consistency">
					<CircleBar data={consistencyData}
							   dataName="Consistency"
							   value={Math.round(stats.variancePercentage)}
					/>
				</div>

				<div className="sectionContainer generalStatsContainer">
					<div className="statCardSmall">
						<p>Entries</p>
						<p>{stats.numGames}</p>
					</div>

					<div className="statCardSmall">
						<p>Profit</p>
						<p><ColoredStatistic
							value={formatCurrency(stats.cumulativeTotal)}
							isGreen={stats.cumulativeTotal >= 0}/></p>
					</div>

					<div className="statCardSmall">
						<p>Highest P\L $</p>
						<p><ColoredStatistic value={formatCurrency(stats.maxPl)}
											 isGreen={stats.maxPl >= 0}/></p>
					</div>

					<div className="statCardSmall">
						<p>Highest P\L %</p>
						<p><ColoredStatistic value={`${(stats.maxPlPercent * 100).toFixed(2)}%`}
											 isGreen={stats.maxPlPercent > 0}/></p>
					</div>

					<div className="statCardSmall">
						<p>Lowest P\L $</p>
						<p><ColoredStatistic value={formatCurrency(stats.minPl)}
											 isGreen={stats.minPl >= 0}/></p>
					</div>

					<div className="statCardSmall">
						<p>Avg. P\L $</p>
						<p><ColoredStatistic
							value={formatCurrency(stats.averagePl)}
							isGreen={stats.averagePl >= 0}/></p>
					</div>

					<div className="statCardSmall">
						<p>Avg. P\L %</p>
						<p><ColoredStatistic
							value={`${((stats.averagePl / stats.averageIn) * 100).toFixed(2)}%`}
							isGreen={(((stats.averageOut) - (stats.averageIn)) / (stats.averageIn)) > 0}/>
						</p>
					</div>

					<div className="statCardSmall">
						<p>Avg. P\L w/o Outliers</p>
						<p><ColoredStatistic value={formatCurrency(stats.trimmedMean)}
											 isGreen={stats.trimmedMean >= 0}/></p>
					</div>

					<div className="statCardSmall">
						<p>Total In</p>
						<p>{formatCurrency(stats.totalIn)}</p>
					</div>

					<div className="statCardSmall">
						<p>Avg. Money In</p>
						<p>{formatCurrency(stats.totalIn / stats.numGames)}</p>
					</div>

					<div className="statCardSmall">
						<p>Total Out</p>
						<p>{formatCurrency(stats.totalOut)}</p>
					</div>

					<div className="statCardSmall">
						<p>Avg. Money Out</p>
						<p>{formatCurrency(stats.totalOut / stats.numGames)}</p>
					</div>
				</div>
			</div>
		</Page>
	);
}

export default Analytics;