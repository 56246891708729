import "./leaderboardEntry.css";
import ColoredStatistic from "../colored-statistic/coloredStatistic";
import {formatCurrency} from "../utils";
import React, {useEffect} from "react";

const LeaderboardEntry = ({minStat, maxStat, category, row, index}) => {
	useEffect(() => {
		console.log("Index; " + index)
	}, []);
	return (
		<div className="leaderboardTableEntry">
			<div className={"leaderboardRankContainer" + (index === 0 ? " first" : index === 1 ? " second" : index === 2 ? " third" : " afterThird")}>
				<p className="leaderboardRank">{index + 1}</p>
			</div>

			<img className="leaderboardProfilePicture editedIcon"
				 src="/profile.png"
				 alt="Profile picture"/>

			<div className="nameAndBar">
				<p className="leaderboardEntryName">{row.username}</p>
				<div className="progressContainer">
					<div className="progressFiller" style={{
						width: (
							(category === "totalProfit" ?
								(row.stats.cumulativeTotal - minStat.cumulativeTotal) / (maxStat.cumulativeTotal - minStat.cumulativeTotal)
								: category === "biggestWin" ?
									(row.stats.maxPl - minStat.maxPl) / (maxStat.maxPl - minStat.maxPl)
									: category === "biggestLoss" ?
										(row.stats.minPl - minStat.minPl) / (maxStat.minPl - minStat.minPl)
										: category === "highestAvg" ?
											(row.stats.averagePl - minStat.averagePl) / (maxStat.averagePl - minStat.averagePl)
											: category === "mostConsistent" ?
												((1000 / row.stats.variance) - (1000 / minStat.variance)) / ((1000 / maxStat.variance) - (1000 / minStat.variance))
												: 1) * 100) + "%"
					}}/>
				</div>
			</div>

			<p className="leaderboardValue">{
				category === "totalProfit" ?
					<ColoredStatistic value={formatCurrency(row.stats.cumulativeTotal)}
									  isGreen={row.stats.cumulativeTotal >= 0}/>
					:
					category === "biggestWin" ?
						<ColoredStatistic value={formatCurrency(row.stats.maxPl)}
										  isGreen={row.stats.maxPl >= 0}/>
						:

						category === "biggestLoss" ?
							<ColoredStatistic value={formatCurrency(row.stats.minPl)}
											  isGreen={row.stats.minPl >= 0}/>
							:
							category === "highestAvg" ?
								<ColoredStatistic
									value={formatCurrency(row.stats.averagePl)}
									isGreen={row.stats.averagePl >= 0}/>
								:
								category === "mostConsistent" ?
									(1000 / row.stats.variance).toFixed(2)
									: ""
			}</p>
		</div>
	);
}

export default LeaderboardEntry;