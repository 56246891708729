import "./signup.css";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

const Signup = () => {
	const [inputs, setInputs] = useState({});
	const [pending, setPending] = useState(false);

	const navigate = useNavigate();

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs((values) => ({...values, [name]: value}));
	};

	const submit = (event) => {
		event.preventDefault();

		setPending(true);

		if (inputs.password !== inputs.confirm_password) {
			alert("Passwords do not match. Please try again.");
			setPending(false);
			return;
		}

		fetch("/api/signup", {
			method: "POST",
			body: JSON.stringify({
				first_name: inputs.first_name,
				last_name: inputs.last_name,
				username: inputs.username,
				email: inputs.email,
				password: inputs.password,
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			setPending(false);

			if (response.status === 200) {
				navigate("/login");
				window.location.reload(true);
			} else if (response.status === 400) {
				response.json().then((json) => {
					console.log(json.REQUEST_BODY);
					let msg = "";
					let body = json.REQUEST_BODY;

					for (let i = 0; i < body.length; i++) {
						msg += body[i].message + "\n";
					}

					alert(msg);
				});
			}
		});
	};

	return (
		<div className="signupContainer">
			<div className="signup">
				<p className="formTitle">Sign Up</p>
				<form className="signupForm" onSubmit={submit}>
					<div>
						<label className="formLabel" htmlFor="first_name">First Name</label>
						<input className="formInput"
							   name="first_name"
							   type="text"
							   value={inputs.first_name}
							   onChange={handleChange}
							   required/>
					</div>
					<br/>
					<div>
						<label className="formLabel" htmlFor="last_name">Last Name</label>
						<input className="formInput"
							   name="last_name"
							   type="text"
							   value={inputs.last_name}
							   onChange={handleChange}
							   required/>
					</div>
					<br/>
					<div>
						<label className="formLabel" htmlFor="username">Username</label>
						<input className="formInput"
							   name="username"
							   type="text"
							   value={inputs.username}
							   onChange={handleChange}
							   required/>
					</div>
					<br/>
					<div>
						<label className="formLabel" htmlFor="email">Email</label>
						<input className="formInput"
							   name="email"
							   type="email"
							   value={inputs.email}
							   onChange={handleChange}
							   required/>
					</div>
					<br/>
					<div>
						<label className="formLabel" htmlFor="password">Password</label>
						<input className="formInput"
							   name="password"
							   type="password"
							   value={inputs.password}
							   onChange={handleChange}
							   required/>
					</div>
					<div>
						<label className="formLabel" htmlFor="confirm_password">Confirm Password</label>
						<input className="formInput"
							   name="confirm_password"
							   type="password"
							   value={inputs.confirm_password}
							   onChange={handleChange}
							   required/>
					</div>
					<p hidden={!pending}>Pending...</p>
					<br/>
					<br/>

					<button className="signupSubmitButton" type="submit" disabled={pending}>Sign Up</button>
					<div className="actionButtons">
						<button onClick={() => navigate("/login")}>Login</button>
						<button onClick={() => navigate("/")}>Back</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default Signup;