import "./circleBar.css";
import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";
import React from "react";

const CircleBar = ({data, dataName, value}) => {
	return (
		<div className="circleBar">
			<p className="circleBarTitle">{dataName}</p>

			<div className="circleBarGraph">
				<ResponsiveContainer>
					<PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
						<Pie
							data={data}
							innerRadius={25}
							outerRadius={30}
							dataKey="value"
							stroke="none"
						>
							{
								data.map((entry, index) => (
									<Cell key={`cell-${index}`}
										  fill={entry.fillColor}/>
								))
							}
						</Pie>

						{
							value && (
								<text
									x="50%"
									y="50%"
									textAnchor="middle"
									dominantBaseline="central"
									className="circleBarValueText"
								>
									{`${value}%`}
								</text>
							)
						}
					</PieChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};

export default CircleBar;