import "./dashboardNavbar.css";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Modal from "../modal/modal";
import {getAllTags } from "../utils";
import GameForm from "../forms/game-form/gameForm";
import {getMyGameHistory} from "../api";

const DashboardNavbar = () => {
	const [gameHistory, setGameHistory] = useState([]);
	const [showNewGameModal, setShowNewGameModal] = useState(false);
	const nav = useNavigate();

	useEffect(() => {
		getMyGameHistory().then((history) => {
			setGameHistory(history);
		});
	}, []);

	const navigate = (url) => {
		nav(url);
		window.location.reload(); // TODO: Janky fix, rework later.
	};

	let tags = getAllTags(gameHistory);

	return (
		<nav className="dashNav">
			<div className="dashNavItem hoverTransition" onClick={() => navigate("/dashboard")}>
				<img className="navIcon" src="/dashboard.png" alt="Dashboard icon"/>
				<p className="dashNavLink">Dashboard</p>
			</div>
			<div className="dashNavItem hoverTransition" onClick={() => navigate("/analytics")}>
				<img className="navIcon" src="/stats.png" alt="Analytics icon"/>
				<p className="dashNavLink">Analytics</p>
			</div>

			<div className="dashNavItem mobileGameButton" onClick={() => setShowNewGameModal(true)}>
				<img className="navIcon mobileGameButtonIcon" src="/plus.png" alt="New game icon"/>
			</div>

			<div className="dashNavItem hoverTransition" onClick={() => navigate("/tools")}>
				<img className="navIcon" src="/tool.png" alt="Tools icon"/>
				<p className="dashNavLink">Tools</p>
			</div>
			<div className="dashNavItem hoverTransition" onClick={() => navigate("/leagues")}>
				<img className="navIcon" src="/trophy.png" alt="Trophy icon"/>
				<p className="dashNavLink">Leagues</p>
			</div>

			<Modal show={showNewGameModal} handleClose={() => setShowNewGameModal(false)}>
				<div>
					<p className="sectionHeader">Add a New Game</p>
					<div className="newGameContainer">
						<GameForm allTags={tags}/>
					</div>
				</div>
			</Modal>
		</nav>
	);
}

export default DashboardNavbar;