import {useEffect, useState} from "react";

import "./notificationWidget.css";

const NotificationWidget = () => {
	const [notifications, setNotifications] = useState([]);
	const [leagueInvites, setLeagueInvites] = useState([]);
	const [showNotifications, setShowNotifications] = useState(false);

	useEffect(() => {
		getNotifications();
	}, []);

	function getNotifications() {
		fetch("/api/friend-requests", {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				response.json().then((json) => {
					setNotifications(json);
				});
			}
		});

		fetch("/api/league-invites", {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				response.json().then((json) => {
					setLeagueInvites(json);
				});
			}
		});
	}

	return (
		<div className="notificationWidgetContainer">
			<img className="bellIcon"
				 src={notifications.length > 0 || leagueInvites.length > 0 ? "/bell_notif.svg" : "/bell.svg"}
				 alt="Notification Bell"
				 onClick={() => setShowNotifications(!showNotifications)}
			/>

			{
				showNotifications && (
					<div className="notificationsContainer">
						{
							notifications.map((result, index) => (
								<Notification username={result} index={index}/>
							))
						}

						{
							leagueInvites.map((result) => (
								<LeagueNotification leagueName={result.league_name} sender={result.sender}/>
							))
						}
					</div>
				)
			}
		</div>
	);
};

const LeagueNotification = ({leagueName, sender}) => {
	function handleAcceptInvite() {
		fetch("/api/accept-league-invite", {
			method: "POST",
			body: JSON.stringify({
				league_name: leagueName
			}),

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully accepted league invite!");
				window.location.reload();
			}
		});
	}

	function handleDeclineInvite() {
		fetch("/api/decline-league-invite", {
			method: "POST",
			body: JSON.stringify({
				league_name: leagueName
			}),

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully declined league invite!");
				window.location.reload();
			}
		});
	}

	return (
		<div className="notificationItem">
			<p>League invitation to {leagueName} from: {sender}</p>
			<button onClick={handleAcceptInvite}>Accept</button>
			<button onClick={handleDeclineInvite}>Decline</button>
		</div>
	)
}

const Notification = ({username, index}) => {
	function handleAcceptRequest() {
		fetch("/api/confirm-friend", {
			method: "POST",
			body: JSON.stringify({
				username: username
			}),

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully accepted friend request!");
			}
		});
	}

	function handleDeclineRequest() {
		fetch("/api/decline-friend", {
			method: "POST",
			body: JSON.stringify({
				username: username
			}),

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully declined friend request!");
			}
		});
	}

	return (
		<div key={index} className="notificationItem">
			<p>Friend request from: {username}</p>
			<button onClick={handleAcceptRequest}>Accept</button>
			<button onClick={handleDeclineRequest}>Decline</button>
		</div>
	)
};

export default NotificationWidget;