import React, {useState} from "react";

const CreateLeagueForm = () => {
	const [leagueName, setLeagueName] = useState("");

	const submit = (event) => {
		event.preventDefault();

		fetch("/api/create-league", {
			method: "POST",
			body: JSON.stringify({
				name: leagueName
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully created league!"); // TODO: Replace with modal
				window.location.reload();

			} else {
				alert("Error creating league. This name may already be taken."); // TODO: Better errors
			}
		});
	}

	return (
		<div className="createLeagueForm">
			<div>
				<label htmlFor="leagueName">Name:</label>
				<input type="text" name="leagueName" className="textField" placeholder="Enter League Name..."
					   value={leagueName}
					   onChange={(e) => setLeagueName(e.target.value)} required/>
			</div>

			<button className="submitButton" onClick={submit}>Create League</button>
		</div>
	);
};

export default CreateLeagueForm;