import Navbar from "../navbar/navbar";
import DashboardNavbar from "../dashboard-navbar/dashboardNavbar";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import "./page.css";
import Logo from "../logo/logo";

const Page = ({children}) => {
	const navigate = useNavigate();

	return (
		<div className="pageContainer">
			<div className="logoSectionContainer">
				<Logo/>
			</div>

			<div className="navbar">
				<Navbar hideExtras={true}/>
			</div>

			<div className="dashboardNavbar">
				<DashboardNavbar/>
			</div>

			<div className="pageContent">
				{children}
			</div>
		</div>
	);
};

export default Page;