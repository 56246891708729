import React from 'react';
import {Tooltip, ResponsiveContainer, XAxis, YAxis, Area, AreaChart} from 'recharts';
import CustomTooltip from "../tooltip/customTooltip";
import {formatCurrency} from "../utils";

const ProfitGraphTooltip = ({active, payload, label}) => {
	if (active && payload && payload.length) {
		return (
			<CustomTooltip>
				<p className="tooltipDate">{label}</p>
				<p className="purple">{`Profit: ${formatCurrency(payload[0].value)}`}</p>
			</CustomTooltip>
		);
	}

	return null;
};

const ProfitGraph = ({data}) => {
	return (
		<ResponsiveContainer>
			<AreaChart data={data}>
				<Tooltip content={<ProfitGraphTooltip/>}/>
				<XAxis dataKey="name" hide={true}/>
				<YAxis dataKey="value" hide={true}/>
				<Area className="graphArea"
					  type="monotone"
					  dataKey="value"
					  stroke="var(--purple)"
					  strokeWidth={2}
					  fill="var(--purple)"
					  fillOpacity={0.15}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default ProfitGraph;