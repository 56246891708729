import {useState} from "react";

import "./playerSearchBar.css";

const PlayerSearchBar = ({ handleResultClick }) => {
	const [searchText, setSearchText] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [showResults, setShowResults] = useState(false);

	const handleChange = (e) => {
		setSearchText(e.target.value);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			search();
		}
	};

	const onResultClick = (e) => {
		setShowResults(false);
		handleResultClick(e);
	}

	const search = (e) => {
		if (e) e.preventDefault();
		const params = new URLSearchParams({
			username: searchText
		});

		fetch(`/api/search?${params.toString()}`, {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				response.json().then(json => {
					setSearchResults(json.results);
					setShowResults(true);
				});
			}
		});
	}

	return (
		<div className="searchBarContainer">
			<input className="searchText"
				   type="text"
				   onChange={handleChange}
				   onKeyDown={handleKeyDown}
				   value={searchText}
				   placeholder="Search Player Name..."/>
			<button className="searchButton" onClick={search}>
				<img className="editedIcon searchIcon" src="/icons/search.png" alt="Search icon"/>
			</button>

			{
				showResults && (
					<div className="resultsContainer">
						{
							searchResults.map((result, index) => (
									<div
										key={index}
										className="resultItem"
										onClick={() => onResultClick(result)}
									>
										{result}
									</div>
								)
							)
						}
					</div>
				)
			}
		</div>
	);
};

export default PlayerSearchBar;