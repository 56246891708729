import React, {useState} from "react";

import "./dataFiltersForm.css";
import Select from "react-select";

const DataFiltersForm = ({gameHistory, setHistory, tags, closeParentModal}) => {
	// TODO: Not sure why the handleChange way doesn't work here, see if it's possible to collapse all these into one state
	const [stateStartDate, setStartDate] = useState("");
	const [stateEndDate, setEndDate] = useState("");
	const [stateType, setType] = useState("");
	const [stateSb, setSb] = useState("");
	const [stateBb, setBb] = useState("");
	const [stateTags, setTags] = useState([]);

	const submit = (event) => {
		event.preventDefault();

		let filtered = gameHistory.slice();

		console.log(stateTags);

		if (stateStartDate !== "") filtered = filtered.filter((obj) => obj.date >= new Date(stateStartDate).getTime());
		if (stateEndDate !== "") filtered = filtered.filter((obj => obj.date <= new Date(stateEndDate).getTime()));
		if (stateType !== "") filtered = filtered.filter((obj) => obj.type === stateType);
		if (stateSb !== "") filtered = filtered.filter((obj) => obj.sb === parseFloat(stateSb));
		if (stateBb !== "") filtered = filtered.filter((obj) => obj.bb === parseFloat(stateBb));
		if (stateTags.length > 0) filtered = filtered.filter((obj) => {
			if (!obj.tags) return false;
			let hasTag = false;

			// TODO: Clean this bit up
			obj.tags.forEach(t => {
				stateTags.forEach(ta => {
					if (ta.label === t) {
						hasTag = true;
					}
				});
			});

			return hasTag;
		});

		setHistory(filtered);

		closeParentModal();
	}

	const clear = (event) => {
		event.preventDefault();

		setStartDate("");
		setEndDate("");
		setType("");
		setSb("");
		setBb("");
		setTags([]);

		setHistory(gameHistory);
	}

	return (
		<div className="gameForm">
			<div className="gameFormFields">
				<div className="gameFormFieldGroup">
					<div className="gameFormField">
						<p>Start Date:</p>
						<input className="dateField" type="date" value={stateStartDate}
							   onChange={(e) => setStartDate(e.target.value)}/>
					</div>

					<div className="gameFormField">
						<p> End Date:</p>
						<input className="dateField" type="date" value={stateEndDate}
							   onChange={(e) => setEndDate(e.target.value)}/>
					</div>
				</div>

				<div className="gameFormField">
					<p>Game Type:</p>
					<select className="typeField" value={stateType} onChange={(e) => setType(e.target.value)}>
						<option value="">None</option>
						<option value="cash">Cash</option>
						<option value="tournament">Tournament</option>
					</select>
				</div>

				<div className="gameFormFieldGroup">
					<div className="gameFormField">
						<p>SB:</p>
						<input type="text" className="textField" placeholder="Enter SB..." value={stateSb}
							   onChange={(e) => setSb(e.target.value)}/>
					</div>

					<div className="gameFormField">
						<p>BB:</p>
						<input type="text" className="textField" placeholder="Enter BB..." value={stateBb}
							   onChange={(e) => setBb(e.target.value)}/>
					</div>
				</div>

				<div className="gameFormField">
					<p>Tags:</p>
					<Select
						className="tagSelector"
						name="tags"
						isMulti
						options={tags}
						value={stateTags}
						onChange={(e) => setTags(e)}
					/>
				</div>
			</div>

			<button className="clearButton" onClick={clear}>Clear</button>
			<button className="submitButton" onClick={submit}>Apply</button>
		</div>
	);
}

export default DataFiltersForm;