import "./tooltip.css";
import React from "react";

const CustomTooltip = ({children}) => {
	return (
		<div className="tooltipContainer">
			{children}
		</div>
	);
};


export default CustomTooltip;