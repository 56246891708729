import "./modal.css";

const Modal = ({handleClose, show, children}) => {
	const showHideClassName = show ? 'modal display-block' : "modal display-none";
	return (
		<div className={showHideClassName}>
			<div className="modal-main">
				<div className="modalChildren">
					{children}
				</div>
				<button onClick={handleClose}>Close</button>
			</div>
		</div>
	);
}

export default Modal;