import React, {useEffect, useState} from "react";

import "./dashboard.css"
import GameEntryTable from "../game-entry-table/gameEntryTable";
import ColoredStatistic from "../colored-statistic/coloredStatistic";
import {formatCurrency, calculateHistoryStats, calculateAccuracy, getAllTags, getGraphData} from "../utils";
import ProfitGraph from "../profit-graph/profitGraph";
import DataFiltersForm from "../forms/data-filters-form/dataFiltersForm";
import {useNavigate} from "react-router-dom";
import Modal from "../modal/modal";
import ImportHistoryForm from "../forms/import-history-form/importHistoryForm";
import StatCard from "../stat-card/statCard";
import Page from "../page/page";
import GameForm from "../forms/game-form/gameForm";

const Dashboard = () => {
	const [gameHistory, setGameHistory] = useState([]);
	const [filteredGameHistory, setFilteredGameHistory] = useState([]);

	const [loggedInUsername, setLoggedInUsername] = useState("Not logged in.")
	const [profileUsername, setProfileUsername] = useState("");
	const [isOwnProfile, setIsOwnProfile] = useState(false);
	const [showNewGameModal, setShowNewGameModal] = useState(false);
	const [showFiltersModal, setShowFiltersModal] = useState(false);
	const [showImportModal, setShowImportModal] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		getLoggedInUsername();
		let checkedProfile = false;
		if (checkOwnProfile()) checkedProfile = true // Have to do this bc useState is async and not awaitable. TODO: Find a better way to do this
		getGameHistory(checkedProfile);
		getAccountInfo(checkedProfile);
	}, []);

	function getLoggedInUsername() {
		fetch("/api/account", {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				response.json().then((json) => {
					setLoggedInUsername(json.username);
				});
			}
		});
	}

	function getAccountInfo(checkedProfile) {
		let pathName = window.location.pathname;

		if (checkedProfile) {
			// TODO: There's no way we need this many calls to /api/account, reduce this
			fetch("/api/account", {
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
				}

			}).then((response) => {
				if (response.status === 200) {
					response.json().then((json) => {
						setProfileUsername(json.username);
					});
				}
			});

			return;
		}

		fetch("/api/account/" + pathName.replace("/dashboard/", ""), {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				response.json().then((json) => {
					setProfileUsername(json.username);
					// TODO: Set more info like friends, etc.
				});
			}
		});
	}

	function checkOwnProfile() {
		let pathName = window.location.pathname;
		if (!pathName.includes("/dashboard/")) {
			setIsOwnProfile(true);
			return true;
		}

		fetch("/api/account", {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				response.json().then((json) => {
					if (json.username.toLowerCase() === pathName.replace("/dashboard/", "").toLowerCase()) {
						navigate("/dashboard");
						window.location.reload();
					}
				});
			}
		});
	}

	function getGameHistory(checkedProfile) {
		let pathName = window.location.pathname;

		fetch(checkedProfile ? "/api/game-history" : `/api/game-history/${pathName.replace("/dashboard/", "")}`, {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				response.json().then((json) => {
					setGameHistory(json.history);
					setFilteredGameHistory(json.history);
				});
			}
		});
	}

	let stats = calculateHistoryStats(filteredGameHistory);
	let graphData = getGraphData(filteredGameHistory);
	let tags = getAllTags(gameHistory);
	let lastGame = filteredGameHistory.slice().sort((a, b) => (b.date - a.date))[0];

	return (
		<Page>
			<div className="dashboardContainer">
				<div className="statCards">
					<StatCard
						title="Profit"
						image="/dollar-symbol.png"
						value={
							<ColoredStatistic value={formatCurrency(stats.cumulativeTotal)}
											  isGreen={stats.cumulativeTotal >= 0}/>
						}
						changeValue={
							lastGame != null ?
								<ColoredStatistic
									value={(lastGame.cashed > lastGame.bought ? "+" : "") + formatCurrency(lastGame.cashed - lastGame.bought)}
									isGreen={(lastGame.cashed - lastGame.bought) >= 0}
								/> : "+N/A"
						}
						isHighlighted={false}/>
					<StatCard
						title="Avg Profit"
						image="/dollar-symbol.png"
						value={
							<ColoredStatistic value={formatCurrency(stats.averagePl)}
											  isGreen={stats.averagePl >= 0}
							/>
						}/>
					<StatCard
						title="Avg % Profit"
						image="/percentage.png"
						value={
							<ColoredStatistic value={`${((stats.averagePl / stats.averageIn) * 100).toFixed(2)}%`}
											  isGreen={stats.averagePl / stats.averageIn >= 0}/>
						}/>
					<StatCard
						title="Games Played"
						image="/game-controller.png"
						value={stats.numGames}/>
				</div>

				<div className="sectionContainer entriesSectionContainer">
					<p className="sectionHeader">Game History</p>
					<div className="gameModalButtons">
						<Modal show={showImportModal} handleClose={() => setShowImportModal(false)}>
							<div>
								<p className="sectionHeader">Import History</p>
								<p>To import game history, please fill out this spreadsheet: <a
									href="https://docs.google.com/spreadsheets/d/18dSLC9iUJHQkkAuDhiMB4ib7dZcvHWfTXhRd3opoTF4/edit?usp=sharing"
									target="_blank" rel="noopener noreferrer">Link</a> and import the file below.
								</p>
								<div className="importContainer">
									<ImportHistoryForm/>
								</div>
							</div>
						</Modal>
						<button onClick={() => setShowImportModal(true)}>
							<img className="editedIcon buttonIcon" src="/icons/import.png" alt="Import icon"/>
							Import
						</button>

						<Modal show={showFiltersModal} handleClose={() => setShowFiltersModal(false)}>
							<div>
								<p className="sectionHeader">Set Game Filters</p>
								<div className="filtersContainer">
									<DataFiltersForm gameHistory={gameHistory}
													 setHistory={(a) => setFilteredGameHistory(a)}
													 tags={tags}
													 closeParentModal={() => setShowFiltersModal(false)}/>
								</div>
							</div>
						</Modal>

						<button onClick={() => setShowFiltersModal(true)}>
							<img className="editedIcon buttonIcon" src="/icons/filter.png" alt="Filter icon"/>
							Filters
						</button>

						<Modal show={showNewGameModal} handleClose={() => setShowNewGameModal(false)}>
							<div>
								<p className="sectionHeader">Add a New Game</p>
								<div className="newGameContainer">
									<GameForm allTags={tags}/>
								</div>
							</div>

						</Modal>
						<button onClick={() => setShowNewGameModal(true)}>
							<img className="editedIcon buttonIcon" src="/icons/add-square.png" alt="New game icon"/>
							Add Game
						</button>
					</div>

					<GameEntryTable gameHistory={filteredGameHistory} allTags={tags}/>
				</div>

				<div className="sectionContainer graphSectionContainer">
					<p className="sectionHeader">Profit Graph</p>
					<div className="graphContainer">
						<ProfitGraph data={graphData}/>
					</div>
				</div>
			</div>
		</Page>
	);
}

export default Dashboard;
