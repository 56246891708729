import React from "react";

import "./logo.css";
import {useNavigate} from "react-router-dom";

const Logo = () => {
	const navigate = useNavigate();

	return (
		<div className="logoContainer" onClick={() => navigate("/")}>
			<img className="logo" src="/logo.svg" alt="logo"/>
			<p className="nameText">SUITED</p>
		</div>
	);
}

export default Logo;