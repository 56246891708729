import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import "./profile.css";
import Page from "../page/page";
import {
	addFriend,
	getAccountCreatedDate, getAccountInfo,
	getFriends, getGameHistory, getIncomingFriendRequests,
	getLoggedInUsername, getMyGameHistory, getOutgoingFriendRequests,
	removeFriend
} from "../api";
import {calculateHistoryStats, formatCurrency } from "../utils";
import ColoredStatistic from "../colored-statistic/coloredStatistic";
import Modal from "../modal/modal";

const Profile = () => {
	const [friends, setFriends] = useState([]);
	const [outgoingFriendRequests, setOutgoingFriendRequests] = useState([]);
	const [incomingFriendRequests, setIncomingFriendRequests] = useState([]);
	const [showFriends, setShowFriends] = useState(false);

	const [isOwnProfile, setIsOwnProfile] = useState(false);
	const [loggedInUsername, setLoggedInUsername] = useState("Not Logged In.");
	const [signupDate, setSignupDate] = useState(new Date());
	const [profileUsername, setProfileUsername] = useState();

	const [myStats, setMyStats] = useState({});
	const [profileStats, setProfileStats] = useState({});

	const navigate = useNavigate();

	useEffect(() => {
		let pathName = window.location.pathname;
		let pathUserName = pathName.replace("/profile/", "");
		getAccountInfo(pathUserName).then((res) => {
			setProfileUsername(res.username);
		})

		getOutgoingFriendRequests().then((requests) => {
			setOutgoingFriendRequests(requests);
		});

		getIncomingFriendRequests().then((requests) => {
			setIncomingFriendRequests(requests);
		})

		getLoggedInUsername().then((username) => {
			setLoggedInUsername(username);

			if (pathUserName.toLowerCase() === username.toLowerCase()) {
				setIsOwnProfile(true);
			}
		});

		getGameHistory(pathUserName).then((history) => {
			setProfileStats(calculateHistoryStats(history));
		});

		getMyGameHistory().then((history) => {
			setMyStats(calculateHistoryStats(history));
		});

		getFriends(pathUserName).then((f) => {
			setFriends(f);
		});

		getAccountCreatedDate(pathUserName).then((d) => {
			setSignupDate(d);
		});
	}, []);

	const getSignupDateText = (date) => {
		let d = new Date(0);
		d.setUTCMilliseconds(date);
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

		return months[d.getUTCMonth()] + " " + d.getUTCFullYear();
	}

	return (
		<Page>
			<div className="profileContainer">
				<div className="profileHeader">
					<img className="profilePicture editedIcon" hidden={profileUsername === "Not Logged In."}
						 src="/profile.png"
						 alt="Profile picture"/>
					<p className="profileUsername">{profileUsername}</p>
					<p className="signupDate">Member since {getSignupDateText(signupDate)}</p>

					<div className="friendsSection">
						<p className="friends">Friends: {friends != null ? friends.length : 0}</p>
						<div className="buttonsSection">
							{
								!isOwnProfile && !friends.includes(loggedInUsername) ?
									<button className="friendButton"
											disabled={(outgoingFriendRequests.includes(profileUsername) || incomingFriendRequests.includes(profileUsername))}
											onClick={() => addFriend(profileUsername).then(() => window.location.reload())}>
										{
											outgoingFriendRequests.includes(profileUsername) ? "Friend Request Sent" :
												incomingFriendRequests.includes(profileUsername) ? "Friend Request Pending" :
													"Add Friend"
										}
									</button>
									:
									""
							}

							<button className="showFriendsButton"
									onClick={() => setShowFriends(true)}>
								Show Friends
							</button>
						</div>

						<Modal show={showFriends} handleClose={() => setShowFriends(false)}>
							<div>
								<p className="sectionHeader">Friends List</p>
								<div className="friendsList">
									{
										friends.map((result, index) => (
											<div className="friendItem">
												<p onClick={() => {
													navigate("/profile/" + result);
													window.location.reload();
												}}>{result}</p>

												{
													isOwnProfile &&
													<button onClick={() => removeFriend(result)}>Remove</button>
												}
											</div>
										))
									}
								</div>
							</div>
						</Modal>
					</div>
				</div>

				<div className="statComparison">
					{
						profileStats && myStats && profileStats.variancePercentage != null && myStats.variancePercentage != null && (
							<table>
								<thead>
								<tr>
									<th>{profileUsername}</th>
									<th>Stat</th>
									<th>{loggedInUsername}</th>
								</tr>
								</thead>

								<tbody>
								<tr>
									<td>{<ColoredStatistic value={formatCurrency(profileStats.cumulativeTotal)}
														   isGreen={profileStats.cumulativeTotal >= myStats.cumulativeTotal}/>}</td>
									<td>Total Profit</td>
									<td>{<ColoredStatistic value={formatCurrency(myStats.cumulativeTotal)}
														   isGreen={myStats.cumulativeTotal >= profileStats.cumulativeTotal}/>}</td>
								</tr>
								<tr>
									<td>{<ColoredStatistic value={profileStats.numGames}
														   isGreen={profileStats.numGames >= myStats.numGames}/>}</td>
									<td>Games Played</td>
									<td>{<ColoredStatistic value={myStats.numGames}
														   isGreen={myStats.numGames >= profileStats.numGames}/>}</td>
								</tr>
								<tr>
									<td>{<ColoredStatistic value={`${Math.round(profileStats.winRate * 10000) / 100}%`}
														   isGreen={profileStats.winRate >= myStats.winRate}/>}</td>
									<td>Win Rate</td>
									<td>{<ColoredStatistic value={`${Math.round(myStats.winRate * 10000) / 100}%`}
														   isGreen={myStats.winRate >= profileStats.winRate}/>}</td>
								</tr>
								<tr>
									<td>{<ColoredStatistic
										value={`${Math.round(profileStats.maxPlPercent * 10000) / 100}%`}
										isGreen={profileStats.maxPlPercent >= myStats.maxPlPercent}/>}</td>
									<td>Highest P/L %</td>
									<td>{<ColoredStatistic value={`${Math.round(myStats.maxPlPercent * 10000) / 100}%`}
														   isGreen={myStats.maxPlPercent >= profileStats.maxPlPercent}/>}</td>
								</tr>
								<tr>
									<td>{<ColoredStatistic
										value={`${profileStats.variancePercentage.toFixed(2)}%`}
										isGreen={profileStats.variancePercentage >= myStats.variancePercentage}/>}</td>
									<td>Consistency</td>
									<td>{<ColoredStatistic
										value={`${myStats.variancePercentage.toFixed(2)}%`}
										isGreen={myStats.variancePercentage >= profileStats.variancePercentage}/>}</td>
								</tr>
								</tbody>
							</table>
						)
					}
				</div>
			</div>
		</Page>
	);
};

export default Profile;