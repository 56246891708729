import React, {useState} from "react";

import "./chipCalculator.css";

const ChipCalculator = () => {
	const [chips, setChips] = useState([{value: '', count: ''}]);
	const [total, setTotal] = useState(0);

	const handleChange = (index, event) => {
		const {name, value} = event.target;
		const newChips = [...chips];
		newChips[index][name] = value;
		setChips(newChips);
		calculateTotal(newChips);
	}

	const addChipEntry = () => {
		setChips([...chips, {profit: '', count: ''}]);
	};

	const deleteChipEntry = (index) => {
		const newChips = chips.filter((_, i) => i !== index);
		setChips(newChips);
		calculateTotal(newChips);
	};

	const calculateTotal = (chips) => {
		const totalSum = chips.reduce((sum, chip) => {
			const chipValue = parseFloat(chip.value);
			const chipCount = parseInt(chip.count, 10);
			if (!isNaN(chipValue) && !isNaN(chipCount)) {
				return sum + (chipValue * chipCount);
			}
			return sum;
		}, 0);
		setTotal(totalSum);
	};

	return (
		<div className="chipCalculatorContainer">
			<div className="functionArea">
				{chips.map((chip, index) => (
					<div className="chipSection" key={index}>
						<div className="chipSubSection">
							<label className="helperLabel" htmlFor="value">Chip Value:</label>
							<input
								className="entryInput"
								type="number"
								name="value"
								placeholder="Chip Value"
								value={chip.value}
								onChange={(event) => handleChange(index, event)}
							/>
						</div>

						<div className="chipSubSection">
							<label className="helperLabel" htmlFor="count">Chip Count:</label>
							<input
								className="entryInput"
								type="number"
								name="count"
								placeholder="Chip Count"
								value={chip.count}
								onChange={(event) => handleChange(index, event)}
							/>
						</div>

						<button className="deleteButton" onClick={() => deleteChipEntry(index)}>Delete</button>
					</div>
				))}
				<button className="addChipButton" onClick={addChipEntry}>Add</button>
			</div>
			<p>Total: {total}</p>
		</div>
	);
}

export default ChipCalculator;