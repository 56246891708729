import "./statCard.css";

const StatCard = ({ image, title, value, changeValue, isHighlighted }) => {
    return (
        <div className={"statCard" + (isHighlighted ? " highlighted" : "")}>
            <div className="statImageContainer">
                <img className="statImage" src={image} alt="Stat image"/>
            </div>

            <div className="statContent">
                <p className="statTitle">{title}</p>

                <div className="values">
                    <p className="statValue">{value}</p>
                    {changeValue ? <p className="changeValue">({changeValue})</p> : ""}
                </div>
            </div>
        </div>
    );
};

export default StatCard;