import React, {useEffect, useState} from "react";
import CreatableSelect from "react-select/creatable";
import Modal from "../../modal/modal";

import "./gameForm.css";

const GameForm = ({
					  type,
					  allTags,
					  gameId,
					  prevDate,
					  prevType,
					  prevSb,
					  prevBb,
					  prevBought,
					  prevCashed,
					  prevTags,
					  prevNotes
				  }) => {
	const [inputs, setInputs] = useState({
		date: prevDate,
		type: prevType,
		sb: prevSb,
		bb: prevBb,
		bought: prevBought,
		cashed: prevCashed,
		tags: prevTags,
		notes: prevNotes
	});
	const [gameTags, setGameTags] = useState([]);
	const [alertMessage, setAlertMessage] = useState("");
	const [showAlert, setShowAlert] = useState(false);

	useEffect(() => {
		if (!prevTags || prevTags.length === 0) return;
		let prevTagsArr = [];
		prevTags.forEach((t) => {
			prevTagsArr.push({
				value: t,
				label: t
			});
		});

		setGameTags(prevTagsArr);

		let blankDate = new Date(0);
		let dt = blankDate.setUTCMilliseconds(prevDate);
		let value = new Date(dt).toISOString().split('T')[0];
		setInputs((values) => ({...values, ["date"]: value}));
	}, []);

	const handleAlertClose = (e) => {
		e.preventDefault();
		setShowAlert(false);
		window.location.reload();
	}

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs((values) => ({...values, [name]: value}));
	};

	const deleteGame = (event) => {
		event.preventDefault();

		let conf = window.confirm("Are you sure you want to delete this game?\nClick OK to delete, or cancel to cancel.");
		if (!conf) return;

		fetch("/api/delete-game", {
			method: "POST",
			body: JSON.stringify({
				game_id: gameId
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				setAlertMessage("Successfully deleted game!");
				setShowAlert(true);
			}
		})
	};

	const editGame = (event) => {
		event.preventDefault();

		fetch("/api/edit-game", {
			method: "POST",
			body: JSON.stringify({
				game_id: gameId,
				date: inputs.date,
				type: inputs.type,
				sb: inputs.sb,
				bb: inputs.bb,
				bought: inputs.bought,
				cashed: inputs.cashed,
				tags: gameTags,
				notes: inputs.notes
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				setAlertMessage("Successfully edited game!");
				setShowAlert(true);
			}
		})
	};

	const createGame = (event) => {
		event.preventDefault();

		fetch("/api/create-game", {
			method: "POST",
			body: JSON.stringify({
				date: inputs.date,
				type: inputs.type,
				sb: inputs.sb,
				bb: inputs.bb,
				bought: inputs.bought,
				cashed: inputs.cashed,
				tags: gameTags,
				notes: inputs.notes
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully added game!"); // TODO: Replace with modal
				window.location.reload();
			} else {
				response.json().then(json => {
					let errCollection = "";
					if (json.REQUEST_BODY) {
						json.REQUEST_BODY.forEach(err => {
							errCollection += "\n" + err.message;
						});
					}

					setAlertMessage("Error adding game. Errors:" + errCollection);
					setShowAlert(true);
				});
			}
		});
	}

	return (
		<div className="gameForm">
			<Modal show={showAlert} handleClose={handleAlertClose}>
				<div>
					<p>{alertMessage}</p>
				</div>
			</Modal>

			<div className="gameFormFields">
				<div className="gameFormFieldGroup">
					<div className="gameFormField">
						<p>Date:</p>
						<input name="date" className="textField" type="date" value={inputs.date}
							   onChange={handleChange} required/>
					</div>

					<div className="gameFormField">
						<p>Game Type:</p>
						<select name="type" value={inputs.type} onChange={handleChange} required>
							<option value="">None</option>
							<option value="cash">Cash</option>
							<option value="tournament">Tournament</option>
						</select>
					</div>
				</div>

				<div className="gameFormFieldGroup">
					<div className="gameFormField">
						<p>SB:</p>
						<input type="text" name="sb" className="textField" placeholder="Enter SB..." value={inputs.sb}
							   onChange={handleChange} required/>
					</div>
					<div className="gameFormField">
						<p>BB:</p>
						<input type="text" name="bb" className="textField" placeholder="Enter BB..." value={inputs.bb}
							   onChange={handleChange} required/>
					</div>
				</div>

				<div className="gameFormFieldGroup">
					<div className="gameFormField">
						<p>Chips Bought:</p>
						<input type="text" name="bought" className="textField" placeholder="Enter Chips Bought..."
							   value={inputs.bought}
							   onChange={handleChange} required/>
					</div>

					<div className="gameFormField">
						<p>Chips Cashed:</p>
						<input type="text" name="cashed" className="textField" placeholder="Enter Chips Cashed..."
							   value={inputs.cashed}
							   onChange={handleChange} required/>
					</div>
				</div>

				<div className="gameFormField">
					<p>Select Tags</p>
					<CreatableSelect
						className="tagSelector"
						styles={{
							control: (provided, state) => ({
								...provided,
								background: '#fff',
								borderColor: '#9e9e9e',
								minHeight: '30px',
								height: '30px',
								fontSize: '0.8rem',
								boxShadow: state.isFocused ? null : null,
							}),
							valueContainer: (provided, state) => ({
								...provided,
								height: '30px',
								padding: '0 6px'
							}),
							input: (provided, state) => ({
								...provided,
								margin: '0px',
							}),
							indicatorSeparator: state => ({
								display: 'none',
							}),
							indicatorsContainer: (provided, state) => ({
								...provided,
								height: '30px',
							})
						}}
						isMulti
						options={allTags}
						value={gameTags}
						onChange={(e) => setGameTags(e)}
					/>
				</div>

				<div className="gameFormField">
					<p>Notes:</p>
					<textarea name="notes" className="textField notes" placeholder="Enter Game Notes..."
							  value={inputs.notes}
							  onChange={handleChange}></textarea>
				</div>

				<div className="gameFormActionButtons">
					{type === "edit" &&
						<button className="gameFormActionButton" onClick={deleteGame}>Delete Game</button>}
					{type === "edit" && <button className="gameFormActionButton" onClick={editGame}>Edit Game</button>}
					{type !== "edit" &&
						<button className="gameFormActionButton" onClick={createGame}>Create Game</button>}
				</div>
			</div>
		</div>
	);
};

export default GameForm;