export function formatCurrency(value) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});

	const formattedValue = formatter.format(value);

	if (value < 0) {
		return `-${formattedValue.replace('-', '')}`
	}

	return formattedValue;
}

export function calculateHistoryStats(gameHistory) {
	let cumulativeTotal = 0;
	let maxPl = -Infinity;
	let minPl = Infinity;
	let maxPlPercent = -Infinity;

	const numGames = gameHistory.length;

	let positiveCount = 0;

	let totalIn = 0;
	let totalOut = 0;

	gameHistory.forEach((row) => {
		let pL = row.cashed - row.bought;
		cumulativeTotal += pL;

		if (pL > maxPl) maxPl = pL;

		if (pL < minPl) minPl = pL;

		if (pL / row.bought > maxPlPercent) maxPlPercent = pL / row.bought;

		if (pL >= 0) positiveCount++;

		totalIn += row.bought;
		totalOut += row.cashed;
	});

	let sortedArr = gameHistory.slice().sort((a, b) => {
		let aPl = a.cashed - a.bought;
		let bPl = b.cashed - b.bought;
		return aPl - bPl;
	});

	let numToRemove = Math.floor(sortedArr.length * 0.2);
	let trimmedArr = sortedArr.slice(numToRemove, sortedArr.length - numToRemove);

	let sum = trimmedArr.reduce((acc, val) => acc + (val.cashed - val.bought), 0);
	let trimmedMean = sum / trimmedArr.length;

	// Variance calculations
	const mean = gameHistory.reduce((acc, val) => acc + ((val.cashed - val.bought) / val.bought), 0) / gameHistory.length;
	const squaredDifferences = gameHistory.map(val => Math.pow(((val.cashed - val.bought) / val.bought) - mean, 2));
	const variance = squaredDifferences.reduce((acc, val) => acc + val, 0) / gameHistory.length;

	// Inverse normalized variance calculations
	const maxVariance = Math.max(...gameHistory.map(value => Math.pow(((value.cashed - value.bought) / value.bought) - (gameHistory.reduce((acc, val) => acc + ((val.cashed - val.bought) / val.bought), 0) / gameHistory.length), 2)));
	const minVariance = 0;
	const normalizedVariance = (variance - minVariance) / (maxVariance - minVariance);

	const variancePercentage = (1 - normalizedVariance) * 100;

	let accuracy = calculateAccuracy(gameHistory, cumulativeTotal, numGames, trimmedMean);

	return {
		cumulativeTotal: cumulativeTotal,
		maxPl: maxPl,
		minPl: minPl,
		maxPlPercent: maxPlPercent,
		numGames: numGames,
		positiveCount: positiveCount,
		totalIn: totalIn,
		totalOut: totalOut,
		trimmedMean: trimmedMean,
		variance: variance,
		accuracy: accuracy,
		// Calculated statistics
		averagePl: cumulativeTotal / numGames,
		averageIn: totalIn / numGames,
		averageOut: totalOut / numGames,
		variancePercentage: variancePercentage,
		winRate: positiveCount / numGames
	}
}

export function calculateAccuracy(gameHistory, cumulativeTotal, numGames, trimmedMean) {
	let entriesAccuracy = numGames / 50;
	let avgPl = cumulativeTotal / numGames;
	let outlierInfluence = Math.abs((avgPl - trimmedMean) / avgPl);
	let outlierInfluenceAccuracy = Math.min(Math.max(1 - outlierInfluence, 0), 1);

	const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

	let lastEntryDate = new Date(0);
	let sorted = gameHistory.slice().sort((a, b) => (a.date - b.date));

	let daysSinceLastEntryAccuracy = 0;
	if (sorted.length > 0) {
		lastEntryDate.setUTCMilliseconds(sorted[sorted.length - 1].date);
		const daysSinceLastEntry = Math.round(Math.abs((lastEntryDate - Date.now()) / oneDay));
		daysSinceLastEntryAccuracy = Math.max(1 - (daysSinceLastEntry / 30), 0);
	}

	let entriesInLast30Days = 0;
	gameHistory.forEach(e => {
		let cur = new Date(0);
		cur.setUTCMilliseconds(e.date);
		let daysSinceEntry = Math.round(Math.abs((cur - Date.now()) / oneDay));

		if (daysSinceEntry <= 30) entriesInLast30Days++;
	});
	let entriesInLast30DaysAccuracy = Math.min(entriesInLast30Days / 30, 1);
	return (entriesAccuracy * 1.25 + outlierInfluenceAccuracy * 1 + daysSinceLastEntryAccuracy * 1 + entriesInLast30DaysAccuracy * 1.1) / 4;
}

export function getGraphData(gameHistory) {
	let graphData = [];
	let total = 0;
	gameHistory.slice().sort((a, b) => (a.date - b.date)).forEach((row) => {
		total += row.cashed - row.bought;
		let date = new Date(0);
		date.setUTCMilliseconds(row.date);

		graphData.push({
			name: `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`,
			value: Math.round(total * 100) / 100
		});
	});

	return graphData;
}

export function getAllTags(gameHistory) {
	let tags = [];
	gameHistory.forEach((row) => {
		if (row.hasOwnProperty('tags')) {
			row.tags.forEach((tag) => {
				let tagObj = {
					label: tag,
					value: tag
				};

				if (!tags.some(e => e.label === tag)) {
					tags.push(tagObj);
				}
			});
		}
	});

	return tags;
}