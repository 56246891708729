import "./login.css";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Modal from "../modal/modal";

const Login = () => {
	const [inputs, setInputs] = useState({});
	const [remember, setRemember] = useState(false);
	const [pending, setPending] = useState(false);

	const [alertMessage, setAlertMessage] = useState("");
	const [showAlert, setShowAlert] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		setPending(true);

		fetch("/api/auto-login", {
			method: "POST",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			setPending(false);
			if (response.status === 200) {
				navigate("/");
				window.location.reload();
			}
		});
	}, []);

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs((values) => ({...values, [name]: value}));
	};

	const submit = (event) => {
		event.preventDefault();

		setPending(true);

		fetch("/api/login", {
			method: "POST",
			body: JSON.stringify({
				username: inputs.username,
				password: inputs.password,
				remember: remember
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			setPending(false);
			// TODO: Add response messages instead of changing alert based on response code
			if (response.status === 200) {
				navigate("/");
				window.location.reload();
				return;
			}

			if (response.status === 404) {
				setAlertMessage("Account not found.");
				setShowAlert(true);
				return;
			}

			if (response.status === 403) {
				setAlertMessage("Incorrect password.");
				setShowAlert(true);
				return;
			}
		});
	};

	const handleAlertClose = (e) => {
		e.preventDefault();
		setShowAlert(false);
	}

	return (
		<div className="loginContainer">
			<Modal show={showAlert} handleClose={handleAlertClose}>
				<div>
					<p>{alertMessage}</p>
				</div>
			</Modal>
			<div className="login">
				<p className="formTitle">Login</p>

				<form className="loginForm" onSubmit={submit}>
					<div>
						<label className="formLabel" htmlFor="username">Username</label>
						<input className="formInput"
							   name="username"
							   type="text"
							   value={inputs.username}
							   onChange={handleChange}
							   required/>
					</div>
					<br/>
					<div>
						<label className="formLabel" htmlFor="password">Password</label>
						<input className="formInput"
							   name="password"
							   type="password"
							   value={inputs.password}
							   onChange={handleChange}
							   required/>
					</div>
					<br/>
					<div>
						<input
							type="checkbox"
							name="remember"
							placeholder="Enter Remember Me"
							value="remember"
							onChange={() => {
								setRemember(!remember);
							}}
						/>
						<label className="rememberLabel" htmlFor="remember"><b>Remember Me</b></label>
					</div>
					<p hidden={!pending}>Pending...</p>
					<br/>
					<br/>

					<button className="loginSubmitButton" type="submit" disabled={pending}>Login</button>
					<div className="actionButtons">
						<button onClick={() => navigate("/signup")}>Sign Up</button>
						<button onClick={() => navigate("/")}>Back</button>
					</div>
				</form>

			</div>
		</div>
	);
}

export default Login;