import {useNavigate} from "react-router-dom";

import "./navbar.css";
import AccountWidget from "../account-widget/accountWidget";
import PlayerSearchBar from "../player-searchbar/playerSearchBar";
import NotificationWidget from "../notification-widget/notificationWidget";
import Logo from "../logo/logo";

const Navbar = ({hideExtras}) => {
	const nav = useNavigate();

	const navigate = (url) => {
		nav(url);
		window.location.reload(); // TODO: Janky fix, rework later.
	};

	const handleResultClick = (result) => {
		navigate("/profile/" + result);
	}

	return (
		<nav className="nav">
			<div className={"contents" + (hideExtras ? " contentsNoExtras" : "")}>
				<ul className={"navList" + (hideExtras ? " hide" : "")}>
					<li>
						<Logo/>
					</li>
					<li className="navItem" onClick={() => navigate("/")}>
						<p className="navLink">Home</p>
					</li>
					<li className="navItem" onClick={() => navigate("/dashboard")}>
						<p className="navLink">Dashboard</p>
					</li>
					<li className="navItem" onClick={() => navigate("/about")}>
						<p className="navLink">About</p>
					</li>
				</ul>

				<div className="navItem">
					<PlayerSearchBar handleResultClick={handleResultClick}/>
				</div>

				<div className="widgets">
					<div className="notificationWidget">
						<NotificationWidget/>
					</div>

					<div className="accountWidget">
						<AccountWidget/>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default Navbar;